import * as React from "react"
import Main from "../components/Layout/Main";
import funData from "../theme/fun";
import Breadcrumb from "../components/Layout/Breadcrumb";
import Image from "../components/Image";
import Images from "../theme/images";
import DownloadPdf from "../components/DownloadPdf";
import File from "../components/File";
import Seo from "../components/Seo";

const BrincadeirasPage = () => {
    return (
        <Main>
            <Seo title="Brincadeiras" description="A ação de brincar proporciona o desenvolvimento integral da criança. Baixe agora mesmo algumas brincadeiras que separamos para a criançada."/>
            <Breadcrumb title="Brincadeiras"/>
            <div className="container mx-auto px-4 mb-9">
                <p className="text-2xl text-purple text-center font-bold my-10">Faça o download e imprima para desenhar e pintar:</p>
                {
                    funData.fun.map((item, key) => {
                        return(
                            <div className="xl:mx-80 my-6" key={key.toString()}>
                                <File href={item.download} download={true} className="grid grid-cols-2">
                                    <Image src={item.thumbnail} alt={item.name} className="w-full m-auto shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out" />
                                    <div className="flex flex-col justify-center items-center">
                                        <div className="flex flex-row-reverse p-10">
                                            <p className="bg-gradient-to-r from-pink to-purple font-bold text-white text-xl border-2 border-white rounded-full pl-6 pr-2 py-1 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                Download
                                                <img loading="lazy" src={Images.iconChevronDoubleRight} className="float-right ml-2 pt-0.5"  alt="" />
                                            </p>
                                            <img loading="lazy" src={Images.iconPdf} className="w-9 mr-5" alt="" />
                                        </div>
                                    </div>
                                </File>
                            </div>
                        )
                    })
                }
            </div>
            <div className="border-t-4 border-purple bg-purple-custom">
                <div className="container mx-auto">
                    <div className="flex flex-col-reverse md:flex-row">
                        <div className="flex-1">
                            <img loading="lazy" src={Images.folder} className="md:float-right" alt="" />
                        </div>
                        <div className="flex-1 pt-12 px-12 md:pt-10 lg:px-32">
                            <DownloadPdf sendCatalog/>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default BrincadeirasPage
