/* eslint-disable global-require */

exports.fun =  [
    {
        name: "Dinossauro",
        thumbnail: "fun/dinossauro.png",
        download: "fun/dinossauro.pdf"
    },
    {
        name: "Avião",
        thumbnail: "fun/aviao.png",
        download: "fun/aviao.pdf"
    },
    {
        name: "Natureza",
        thumbnail: "fun/natureza.png",
        download: "fun/natureza.pdf"
    },
    {
        name: "Peixe",
        thumbnail: "fun/peixe.png",
        download: "fun/peixe.pdf"
    }
]